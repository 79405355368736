import React from "react";
import { StyledSection2Chakras, StyledSection2ChakrasImage1, StyledSection2ChakrasImage2, StyledSection2ChakrasTexto } from "./Section2Chakras.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section2Chakras = () => {
    return (
        <StyledSection2Chakras>
            <StyledSection2ChakrasImage1 src="img/alineacion de chakras 3.png" alt="chakra1" />
            <StyledSection2ChakrasImage2 src="img/Ellipse 17.svg" alt="chakra2" />
            <StyledSection2ChakrasTexto>
                <Title>La Experiencia de una Sesión de Alineación de Chakras</Title>
                <Paragraph>Una sesión de alineación de chakras se puede realizar de forma presencial o a distancia, con la ayuda de un terapeuta o un guía espiritual. El terapeuta o el guía utiliza diferentes técnicas para detectar y corregir los desequilibrios en los chakras, como el Reiki, la cromoterapia, la sanación con cristales, la respiración, la visualización, los mantras o los sonidos. El terapeuta o el guía te puede dar instrucciones para que te relajes, respires, medites o repitas afirmaciones positivas, mientras te envía energía sanadora a través de las manos o de otros instrumentos. Una sesión involucra un análisis de los chakras, clase de yoga, meditación, mantra y afirmaciones positivas.</Paragraph>
            </StyledSection2ChakrasTexto>
        </StyledSection2Chakras>
    )
}

export default Section2Chakras;