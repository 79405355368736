import { styled } from "../../theme/styled";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

export const StyledSectionPost = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
});

export const StyledSectionPostTitle = styled("div", {
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
    "@media (max-width: 720px)": {
        marginBottom: "0",
        textAlign: "center",
    },
});

export const StyledSectionPostContent = styled("div", {
    maxWidth: "1200px",
    margin: "0 auto",
    marginBottom: "40px",
    "@media (max-width: 720px)": {
        maxWidth: "90%",
        textAlign: "center",
        "& img": {
            maxWidth: "300px",
            height: "auto",
        },
    },
});

export const StyledButtons = styled("div", {
    width: "1200px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "flex-start",
    gap: "20px",
    "@media (max-width: 720px)": {
        width: "90%",
        justifyContent: "flex-start",
    },
});

export const StyledEditButtonContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
        color: "$primary",
    },
});

export const StyledButtonText = styled("span", {
    fontSize: "14px",
    fontWeight: "500",
});

export const StyledEditButtonIcon = styled(FiEdit3, {
    fontSize: "30px",
});

export const StyledDeleteButtonIcon = styled(AiOutlineDelete, {
    fontSize: "30px",
});
