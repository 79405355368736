import { styled } from "../../../theme/styled";

export const StyledSection3Yoga = styled("div", {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "1200px",
    position: "relative",
    margin: "140px auto 440px auto",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "700px",
    },
    "@media (max-width: 720px)": {
        margin: "60px auto 400px auto",
    },
});

export const StyledSection2YogaFondo = styled("div", {
    backgroundColor: "$light",
    height: "600px",
    width: "100%",
    position: "absolute",
    left: "0",
    right: "0",
    "@media (max-width: 720px)": {
        height: "450px",
    },
});

export const StyledSection3YogaImage = styled("img", {
    width: "680px",
    height: "680px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "16px solid $orange",
    zIndex: "1",
    marginLeft: "100px",
    "@media (max-width: 1200px)": {
        width: "540px",
        height: "540px",
        marginLeft: "0",
    },
    "@media (max-width: 900px)": {
        width: "420px",
        height: "420px",
        marginLeft: "0",
    },
    "@media (max-width: 720px)": {
        width: "320px",
        height: "320px",
        margin: "0 auto",
        marginTop: "20px",
        border: "8px solid $orange",
    },
});


export const StyledSection3YogaTextoContainer = styled("div", {
    width: "700px",
    height: "700px",
    backgroundColor: "$orange2",
    borderRadius: "50%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    top: "400px",
    right: "60px",
    zIndex: "2",
    "@media (max-width: 1200px)": {
        width: "560px",
        height: "560px",
        top: "380px",
    },
    "@media (max-width: 900px)": {
        width: "540px",
        height: "540px",
        top: "300px",
    },
    "@media (max-width: 720px)": {
        width: "320px",
        maxHeight: "440px",
        top: "300px",
        borderRadius: "40px",
        left: "50%",
        transform: "translateX(-50%)",
        justifyContent: "center",
    },
});

export const StyledSection3YogaTitle = styled("div", {
    width: "300px",
    marginTop: "100px",
    "@media (max-width: 1200px)": {
        marginTop: "70px",
    },
    "@media (max-width: 900px)": {
        marginTop: "50px",
    },
    "@media (max-width: 720px)": {
        marginTop: "30px",
        width: "300px",
    },
});

export const StyledSection3YogaTexto = styled("div", {
    width: "500px",
    marginTop: "6px",
    "@media (max-width: 1200px)": {
        width: "460px",
        marginTop: "0px",
    },
    "@media (max-width: 900px)": {
        width: "440px",
        marginTop: "0",
    },
    "@media (max-width: 720px)": {
        width: "300px",
        marginTop: "0px",
    },
});

export const StyledSection3YogaImg1 = styled("img", {
    width: "205px",
    height: "205px",
    position: "absolute",
    top: "500px",
    left: "60px",
    zIndex: "3",
    "@media (max-width: 1200px)": {
        width: "170px",
        height: "170px",
    },
    "@media (max-width: 900px)": {
        width: "120px",
        height: "120px",
        top: "330px",
        left: "0px",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledSection3YogaImg2 = styled("img", {
    width: "225px",
    height: "225px",
    position: "absolute",
    top: "100px",
    right: "50px",
    zIndex: "3",
    "@media (max-width: 1200px)": {
        width: "170px",
        height: "170px",
    },
    "@media (max-width: 900px)": {
        width: "140px",
        height: "140px",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledSection3YogaImg3 = styled("img", {
    width: "180px",
    height: "180px",
    position: "absolute",
    top: "850px",
    left: "220px",
    "@media (max-width: 1200px)": {
        width: "140px",
        height: "140px",
    },
    "@media (max-width: 900px)": {
        display: "none",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});