import React from "react";
import { StyledSection1Chakras, StyledSection1ChakrasImage, StyledSection1ChakrasImageFondo, StyledSection1ChakrasTexto } from "./Section1Chakras.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section1Chakras = () => {
    return (
        <StyledSection1Chakras>
            <StyledSection1ChakrasTexto>
                <Title color="black" size="sm">¿Para qué Sirve la Alineación de Chakras?</Title>
                <Paragraph color="dark" size="md">La alineación de chakras puede ayudarte a mejorar tu bienestar general, ya que te permite liberar las tensiones, las emociones negativas y los patrones mentales que te limitan. Al alinear tus chakras, puedes sentirte más tranquilo, feliz, creativo y conectado con tu propósito de vida.</Paragraph>
            </StyledSection1ChakrasTexto>
            <StyledSection1ChakrasImageFondo>
                <StyledSection1ChakrasImage src="img/alineacion de chakras 2.png" alt="Alineación de Chakras2" />
            </StyledSection1ChakrasImageFondo>
        </StyledSection1Chakras>
    )
};

export default Section1Chakras;