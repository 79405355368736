import React from "react";
import {
    StyledContact,
    StyledContactButtons,
    StyledContactParagraph,
} from "./Contact.styles";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";
import ButtonInstagram from "../ButtonInstagram/ButtonInstagram";

const Contact = () => {
    return (
        <StyledContact id="contact">
            <Title size="sm" color="black">
                Contacto
            </Title>
            <StyledContactParagraph>
                <Paragraph color="dark" size="sm">
                    Si sientes interés por alguna de las terapias que ofrezco,
                    no dudes en ponerte en contacto conmigo. Puedes comunicarte
                    a través de Instagram. Estaré encantado/a de responder a tus
                    preguntas o programar una sesión contigo. ¡Espero tener
                    noticias tuyas pronto!
                </Paragraph>
            </StyledContactParagraph>
            <StyledContactButtons>
                <ButtonInstagram
                    onClick={() => {
                        window.open(
                            "https://www.instagram.com/recordandoterapias/",
                            "_blank"
                        );
                    }}
                />
            </StyledContactButtons>
        </StyledContact>
    );
};

export default Contact;
