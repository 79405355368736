import React from "react";
import { StyledSection1HomeCardLeft, StyledSection1HomeCardRight, StyledSection1HomeTitle, StyledSection2Home } from "./Section2Home.stiles";
import Title from "../../Title/Title";
import CardTerapia from "../../CardTerapia/CardTerapia";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Section2Home = () => {
    const navigate = useNavigate();
    const { ref: refLeft1, inView: inViewLeft1 } = useInView({
        triggerOnce: true,
    });

    const { ref: refRight1, inView: inViewRight1 } = useInView({
        triggerOnce: true,
    });

    const { ref: refLeft2, inView: inViewLeft2 } = useInView({
        triggerOnce: true,
    });

    const { ref: refRight2, inView: inViewRight2 } = useInView({
        triggerOnce: true,
    });

    const { ref: refRight3, inView: inViewRight3 } = useInView({
        triggerOnce: true,
    });




    return (
        <StyledSection2Home>
            <StyledSection1HomeTitle id="terapia">
                <Title size="lg" color="black">Terapias</Title>
            </StyledSection1HomeTitle>
            <StyledSection1HomeCardRight ref={refRight1} isVisible={inViewRight1}>
                <CardTerapia onClick={() => navigate("/registros-akashicos")} terapia="Registros Akashicos" img="img/registros akashicos 1.png" texto="La lectura de los registros akáshicos es una terapia que te permite acceder a la memoria de tu alma, donde se encuentra toda la información de tu pasado, presente y futuro. A través de esta conexión, podrás recibir la guía y el consejo que tu alma te ofrece para tu evolución y tu felicidad. La lectura de los registros akáshicos te ayudará a entender el origen de tus problemas, a sanar tus heridas, a liberar tus miedos, a descubrir tu misión y a vivir en armonía con tu esencia." />
            </StyledSection1HomeCardRight>
            <StyledSection1HomeCardLeft ref={refLeft1} isVisible={inViewLeft1}>
                <CardTerapia onClick={() => navigate("/meditacion")} terapia="Meditación" img="img/meditacion 1.png" texto="La meditación es una práctica que consiste en concentrar la atención en un objeto, un pensamiento, una sensación o la respiración, con el fin de alcanzar un estado de relajación, paz y armonía interior. La meditación tiene muchos beneficios para la salud física y mental, como reducir el estrés, mejorar el sistema inmunológico, aumentar la atención, la memoria y la creatividad, y favorecer el crecimiento espiritual." />
            </StyledSection1HomeCardLeft>
            <StyledSection1HomeCardRight ref={refRight2} isVisible={inViewRight2}>
                <CardTerapia onClick={() => navigate("/yoga")} terapia="Yoga" img="img/yoga 1.png" texto="El yoga es una disciplina que nació en la India hace más de 5.000 años y que engloba toda una forma de vida. Esta práctica incluye ejercicio físico, respiración, meditación y hasta un manual de vida. Su fin último es alcanzar el samadhi o el estado de paz, nirvana, liberación y felicidad." />
            </StyledSection1HomeCardRight>
            <StyledSection1HomeCardLeft ref={refLeft2} isVisible={inViewLeft2}>
                <CardTerapia onClick={() => navigate("/reiki")} terapia="Reiki" img="img/reiki 3.png" texto='El Reiki Usui, desarrollado por Mikao Usui en Japón en el siglo XX, es una técnica de sanación energética que canaliza la energía vital ("Ki" o "Chi") a través del cuerpo mediante la imposición de manos. Se centra en equilibrar y armonizar los aspectos físicos, emocionales, mentales y espirituales, promoviendo la relajación, reduciendo el estrés, el dolor y estimulando la autocuración del cuerpo.' />
            </StyledSection1HomeCardLeft>
            <StyledSection1HomeCardRight ref={refRight3} isVisible={inViewRight3}>
                <CardTerapia onClick={() => navigate("/alineacion-de-chakras")} terapia="Alineacion de chakras" img="img/alineacion de chakras 1.png" texto="La alineación de chakras es el proceso de armonizar los centros energéticos del cuerpo, que se llaman chakras. Los chakras son puntos por donde fluye la energía vital, y cada uno está relacionado con aspectos físicos, emocionales y mentales de nuestra salud. Cuando los chakras están equilibrados, permiten que la energía circule libremente y nos sentimos bien. Cuando los chakras están bloqueados o desequilibrados, pueden causar problemas de salud, estrés, ansiedad o depresión." />
            </StyledSection1HomeCardRight>
        </StyledSection2Home>
    );
};

export default Section2Home;