import { keyframes, styled } from "../../theme/styled";

export const StyledInicioTerapia = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    margin: "80px 0",
    "@media (max-width: 768px)": {
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        margin: "40px 0",
    },
});

const appearAnimationText = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });
  
  const appearAnimationImage = keyframes({
    '0%': { opacity: 0, transform: 'translateX(100px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  });

  const appearAnimationImageMobile = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-50px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  });

export const StyledInicioTerapiaTextContainer = styled("div", {
    width: "340px",
    marginRight: "80px",
    animation: `${appearAnimationText} 1s ease forwards`,
    "@media (max-width: 768px)": {
        width: "100%",
        maxWidth: "340px",
        marginRight: "0",
        marginBottom: "40px",
    },
    
});

export const StyledInicioTerapiaImage = styled("img", {
    maxWidth: "688px",
    objectFit: "cover",
    borderRadius: "20px",
    animation: `${appearAnimationImage} 1s ease forwards`,
    "@media (max-width: 1200px)": {
        width: "500px",
    },
    "@media (max-width: 900px)": {
        width: "380px",
    },
    "@media (max-width: 768px)": {
        width: "320px",
        animation: `${appearAnimationImageMobile} 1s ease forwards`,
    },
    
});