import React from "react";
import { StyledReikiImage, StyledReikiImageContainer, StyledReikiImageFondo, StyledReikiParagraph, StyledReikiTextContainer, StyledSection2Reiki } from "./Section2Reiki.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section2Reiki = ({ title, texto, image }) => {
    return (
        <StyledSection2Reiki>
            <StyledReikiImageContainer>
            <StyledReikiTextContainer>
                    <Title color="black" size="xs">{title}</Title>
                <StyledReikiParagraph>
                    <Paragraph color="dark" size="sm">{texto}</Paragraph>
                </StyledReikiParagraph>
            </StyledReikiTextContainer>
                <StyledReikiImage src={image} />
                <StyledReikiImageFondo />
            </StyledReikiImageContainer>
        </StyledSection2Reiki>
    );
};

export default Section2Reiki;