import { styled } from "../../../theme/styled";

export const StyledSection2Reiki = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    margin: "60px 0 240px 250px",
    "@media (max-width: 1200px)": {
        margin: "60px 0 300px 250px",
    },
    "@media (max-width: 900px)": {
        flexDirection: "column",
        margin: "40px auto 540px auto",
    },
    "@media (max-width: 720px)": {
        margin: "40px auto 480px auto",
    },
});

export const StyledReikiTextContainer = styled("div", {
    width: "480px",
    backgroundColor: "$light",
    padding: "30px",
    borderRadius: "24px",
    position: "absolute",
    top: "160px",
    right: "320px",
    zIndex: "2",
    "@media (max-width: 1200px)": {
        width: "380px",
    },
    "@media (max-width: 900px)": {
        width: "280px",
        padding: "20px",
        textAlign: "center",
        top: "200px",
        right: "50%",
        transform: "translateX(50%)",
    },
});

export const StyledReikiParagraph = styled("div", {
    width: "420px",
    "@media (max-width: 1200px)": {
        width: "320px",
    },
    "@media (max-width: 900px)": {
        width: "280px",
    },
});

export const StyledReikiImageContainer = styled("div", {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 900px)": {
        maxWidth: "360px",
        margin: "auto",
    },
});

export const StyledReikiImageFondo = styled("div", {
    backgroundColor: "$lightorange",
    width: "600px",
    height: "440px",
    position: "absolute",
    top: "36px",
    left: "36px",
    borderRadius: "36px",
    "@media (max-width: 1200px)": {
        width: "500px",
        height: "340px",
    },
    "@media (max-width: 900px)": {
        width: "300px",
        height: "200px",
        top: "20px",
        left: "30px",
        borderRadius: "20px",
    },
});

export const StyledReikiImage = styled("img", {
    width: "600px",
    zIndex: "1",
    "@media (max-width: 1200px)": {
        width: "500px",
    },
    "@media (max-width: 900px)": {
        width: "320px",
    },
});