import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import Header from "../components/Header/Header";
import SectionBlog from "../components/SectionBlog/SectionBlog";
import Title from "../components/Title/Title";
import Paragraph from "../components/Paragraph/Paragraph";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import { convert } from "html-to-text";

const Blog = () => {
    const [postLists, setPostLists] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const isAuth = localStorage.getItem("isAuth");

    const options = {
        selectors: [{ selector: "img", format: "skip" }],
    };

    useEffect(() => {
        const getPosts = async () => {
            const posts = await getDocs(collection(db, "posts"));
            const sortedPosts = posts.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }))
                .sort((a, b) => b.date.toDate() - a.date.toDate());
            setPostLists(sortedPosts);
            setLoading(false);
        };
        getPosts();
    }, []);

    const deletePost = async (id) => {
        const postDoc = doc(db, "posts", id);
        await deleteDoc(postDoc);
    };

    const formatDate = (date) => {
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
        };
        return new Intl.DateTimeFormat("es-ES", options).format(date);
    };

    return (
        <>
            <Header />
            <SectionBlog>
                <SectionBlog.Title>
                    <Title size="sm" color="grey">
                        Explorando el Camino del Bienestar
                    </Title>
                </SectionBlog.Title>
                <SectionBlog.Image src="/img/blog.jpeg" />
                <SectionBlog.Subtitle>
                    <Paragraph color="dark" size="md">
                        Sumérgete en nuestro blog donde compartimos consejos,
                        reflexiones y técnicas para cultivar la paz interior, el
                        equilibrio emocional y el bienestar holístico. ¡Descubre
                        tu camino hacia una vida más plena y saludable!
                    </Paragraph>
                </SectionBlog.Subtitle>
                {loading ? (
                    <Loading visible={loading} />
                ) : (
                    <SectionBlog.Posts>
                        {postLists.map((post) => (
                            <SectionBlog.PostContainer key={post.id}>
                                {isAuth && (
                                    <SectionBlog.DeleteButton
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "¿Estás seguro de que quieres borrar este post?"
                                                )
                                            ) {
                                                deletePost(post.id);
                                                setPostLists(
                                                    postLists.filter(
                                                        (item) =>
                                                            item.id !== post.id
                                                    )
                                                );
                                            }
                                        }}
                                        src="/img/borrar.svg"
                                    />
                                )}
                                <SectionBlog.Post key={post.id}>
                                    <SectionBlog.PostTitle>
                                        <Title size="xs" color="black">
                                            {post.title}
                                        </Title>
                                    </SectionBlog.PostTitle>
                                    <SectionBlog.PostDate>
                                        <Paragraph color="grey" size="sm">
                                            {formatDate(post.date.toDate())}
                                        </Paragraph>
                                    </SectionBlog.PostDate>
                                    <SectionBlog.PostContent>
                                        <Paragraph size="sm" color="dark">
                                            {convert(post.value, options)}
                                        </Paragraph>
                                    </SectionBlog.PostContent>
                                </SectionBlog.Post>
                                <SectionBlog.Button
                                    onClick={() => {
                                        navigate("/blog/" + post.id);
                                    }}
                                >
                                    Ver mas
                                </SectionBlog.Button>
                            </SectionBlog.PostContainer>
                        ))}
                    </SectionBlog.Posts>
                )}
            </SectionBlog>
        </>
    );
};

export default Blog;
