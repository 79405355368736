import { styled } from "../../theme/styled";

export const StyledTitle = styled("h1", {
    margin: "0",
    variants: {
        color: {
            black: {
                color: "$dark",
            },
            white: {
                color: "$lighter",
            },
            light: {
                color: "$lightgrey",
            },
            grey: {
                color: "$grey",
            },
        },
        size: {
            xl: {
                fontSize: "80px",
                "@media (max-width: 900px)": {
                    fontSize: "64px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "48px",
                },
            },
            lg: {
                fontSize: "64px",
                "@media (max-width: 900px)": {
                    fontSize: "48px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "40px",
                },
            },
            md: {
                fontSize: "48px",
                "@media (max-width: 900px)": {
                    fontSize: "40px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "32px",
                },
            },
            sm: {
                fontSize: "44px",
                "@media (max-width: 900px)": {
                    fontSize: "36px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "28px",
                },
            },
            xs: {
                fontSize: "40",
                "@media (max-width: 900px)": {
                    fontSize: "32px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "24px",
                },
            },
        },
    },
});