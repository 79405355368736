import { StyledSectionAdminBlog, StyledSectionAdminBlogButton, StyledSectionAdminBlogContainer, StyledSectionAdminBlogTitle, StyledSectionAdminBlogTitlePost } from "./SectionAdminBlog.styles";

const AdminPost = StyledSectionAdminBlog;

AdminPost.Button = StyledSectionAdminBlogButton;

AdminPost.Title = StyledSectionAdminBlogTitle;

AdminPost.TitlePost = StyledSectionAdminBlogTitlePost;

AdminPost.Container = StyledSectionAdminBlogContainer;

export default AdminPost;