import React from "react";
import { StyledSection1Registros, StyledSection1RegistrosContainer, StyledSection1RegistrosImage1, StyledSection1RegistrosImage2, StyledSection1RegistrosImage3, StyledSection1RegistrosText } from "./Section1Registros.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section1Registros = () => {
    return (
        <StyledSection1Registros>
            <StyledSection1RegistrosContainer>
            <StyledSection1RegistrosImage1 src="img/Ellipse 15.svg" alt="Registros Akashicos" />
            <StyledSection1RegistrosImage2 src="img/Ellipse 16.svg" alt="Registros Akashicos" />
            <StyledSection1RegistrosImage3 src="img/registros akashicos 2.png" alt="Registros Akashicos" />
            <StyledSection1RegistrosText>
                <Title color="black" size="xs">Cómo Acceder a los Registros Akáshicos</Title>
                <Paragraph color="dark" size="md">Los registros akáshicos son los archivos energéticos donde se guarda toda la memoria universal de la existencia. Estos representan un espacio multidimensional en el que se “archivan” todas las experiencias del alma, incluyendo la totalidad de los conocimientos y las experiencias de las vidas pasadas, del presente y de los potenciales futuros. Los registros akáshicos se pueden acceder mediante la clarividencia, la meditación, la oración o la iniciación en una técnica específica. Al consultar los registros akáshicos, se puede recibir orientación, sanación, liberación y empoderamiento para vivir de acuerdo con el plan divino.</Paragraph>
            </StyledSection1RegistrosText>
            </StyledSection1RegistrosContainer>
        </StyledSection1Registros>
    );
};

export default Section1Registros;