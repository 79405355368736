import React, { useEffect, useRef, useState } from "react";
import {
    StyledButtonMenu,
    StyledButtonMenuContent,
    StyledButtonMenuContentLink,
    StyledButtonMenuIcon,
    StyledMenuContainer,
    StyledMenuContainerButton,
    StyledMenuContentButton,
    StyledMenuIconButton,
    StyledMenuTextButton,
} from "./ButtonMenu.styles";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";

const ButtonMenu = ({ isAuth }) => {
    const [open, setOpen] = useState(false);
    const [terapias, setTerapias] = useState(false);
    const [admin, setAdmin] = useState(false);
    const navigate = useNavigate();
    const ref = useRef(null);

    const singUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear();
        });
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);

    return (
        <StyledButtonMenu ref={ref}>
            <StyledButtonMenuIcon onClick={() => setOpen(!open)} />
            <StyledButtonMenuContent open={open}>
                <StyledButtonMenuContentLink onClick={() => navigate("/")}>
                    Inicio
                </StyledButtonMenuContentLink>
                {!isAuth ? (
                    <StyledButtonMenuContentLink
                        onClick={() => navigate("/blog")}
                    >
                        Blog
                    </StyledButtonMenuContentLink>
                ) : null}
                <StyledMenuContainer open={terapias}>
                    <StyledMenuContainerButton
                        onClick={() => setTerapias(!terapias)}
                    >
                        <StyledMenuTextButton>Terapias</StyledMenuTextButton>
                        <StyledMenuIconButton open={terapias} />
                    </StyledMenuContainerButton>
                    <StyledMenuContentButton open={terapias}>
                        <StyledButtonMenuContentLink
                            onClick={() => navigate("/registros-akashicos")}
                        >
                            Registros akashicos
                        </StyledButtonMenuContentLink>
                        <StyledButtonMenuContentLink
                            onClick={() => navigate("/meditacion")}
                        >
                            Meditación
                        </StyledButtonMenuContentLink>
                        <StyledButtonMenuContentLink
                            onClick={() => navigate("/yoga")}
                        >
                            Yoga
                        </StyledButtonMenuContentLink>
                        <StyledButtonMenuContentLink
                            onClick={() => navigate("/reiki")}
                        >
                            Reiki
                        </StyledButtonMenuContentLink>
                        <StyledButtonMenuContentLink
                            onClick={() => navigate("/alineacion-de-chakras")}
                        >
                            Alineación de chakras
                        </StyledButtonMenuContentLink>
                    </StyledMenuContentButton>
                </StyledMenuContainer>
                {isAuth ? (
                    <StyledMenuContainer open={admin}>
                        <StyledMenuContainerButton
                            onClick={() => setAdmin(!admin)}
                        >
                            <StyledMenuTextButton>
                                Administración
                            </StyledMenuTextButton>
                            <StyledMenuIconButton open={admin} />
                        </StyledMenuContainerButton>
                        <StyledMenuContentButton open={admin}>
                            <StyledButtonMenuContentLink
                                onClick={() => navigate("/blog")}
                            >
                                Blog
                            </StyledButtonMenuContentLink>
                            <StyledButtonMenuContentLink
                                onClick={() => navigate("/admin-blog")}
                            >
                                Crear Post
                            </StyledButtonMenuContentLink>
                            <StyledButtonMenuContentLink
                                onClick={() => {
                                    singUserOut();
                                    navigate("/login");
                                }}
                            >
                                Cerrar sesión
                            </StyledButtonMenuContentLink>
                        </StyledMenuContentButton>
                    </StyledMenuContainer>
                ) : null}
                <StyledButtonMenuContentLink as="a" href="#contact">
                    Contacto
                </StyledButtonMenuContentLink>
            </StyledButtonMenuContent>
        </StyledButtonMenu>
    );
};

export default ButtonMenu;
