import React from "react";
import { StyledSection2Registros, StyledSection2RegistrosImage1, StyledSection2RegistrosImage2, StyledSection2RegistrosImage3, StyledSection2RegistrosText } from "./Section2Registros.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section2Registros = () => {
    return (
        <StyledSection2Registros>
            <StyledSection2RegistrosImage1 src="img/Group 1.svg" alt="registros1" />
            <StyledSection2RegistrosImage2 src="img/registros akashicos 3.png" alt="registros2" />
            <StyledSection2RegistrosImage3 src="img/Group 24.svg" alt="registros3" />
            <StyledSection2RegistrosText>
                <Title color="black" size="sm">Cómo es una Sesión de Registros Akáshicos</Title>
                <Paragraph color="dark" size="md">Una sesión de registros akáshicos se puede realizar de forma presencial o a distancia, siempre con el consentimiento y la disposición del consultante. Para realizar la lectura, solo necesito tu nombre completo y tu fecha de nacimiento. La sesión dura entre una hora y hora y media, y se realiza en un ambiente de confianza y respeto. La sesión consiste en una serie de preguntas que el consultante le hace al guía o terapeuta, quien se conecta con los registros akáshicos mediante una oración sagrada y recibe las respuestas de los maestros, guías y seres de luz que custodian los registros. Te invito a que experimentes esta terapia que puede transformar tu vida y tu alma.</Paragraph>
            </StyledSection2RegistrosText>
        </StyledSection2Registros>
    );

};

export default Section2Registros;