import AdminBlog from "./AdminBlog";
import Alineacion from "./Alineacion";
import Blog from "./Blog";
import Editar from "./Editar";
import Home from "./Home";
import Login from "./Login";
import Meditacion from "./Meditacion";
import Post from "./Post";
import RegistrosAkashicos from "./RegistrosAkashicos";
import Reiki from "./Reiki";
import Yoga from "./Yoga";

export const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/reiki",
        component: Reiki,
    },
    {
        path: "/yoga",
        component: Yoga,
    },
    {
        path: "/meditacion",
        component: Meditacion,
    },
    {
        path: "/registros-akashicos",
        component: RegistrosAkashicos,
    },
    {
        path: "/alineacion-de-chakras",
        component: Alineacion,
    },
    {
        path: "/blog",
        component: Blog,
    },
    {
        path: "/blog/:id",
        component: Post,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/admin-blog",
        component: AdminBlog,
    },
    {
        path: "/editar/:id",
        component: Editar,
    },
];
