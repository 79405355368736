import React from "react";
import Header from "../components/Header/Header";
import InicioTerapia from "../components/InicioTerapia/InicioTerapia";
import Contact from "../components/Contact/Contact";
import Section1Chakras from "../components/SectionChakras/Section1Chakras/Section1Chakras";
import Section2Chakras from "../components/SectionChakras/Section2Chakras/Section2Chakras";

const Alineacion = () => {
    return (
        <>
            <Header />
            <InicioTerapia imagen="img/alineacion de chakras 1.png" terapia="Alineación de Chakras" texto="La alineación de chakras es el proceso de armonizar los centros energéticos del cuerpo, que se llaman chakras. Los chakras son puntos por donde fluye la energía vital, y cada uno está relacionado con aspectos físicos, emocionales y mentales de nuestra salud. Cuando los chakras están equilibrados, permiten que la energía circule libremente y nos sentimos bien. Cuando los chakras están bloqueados o desequilibrados, pueden causar problemas de salud, estrés, ansiedad o depresión." />
            <Section1Chakras />
            <Section2Chakras />
            <Contact />
        </>
    );
};

export default Alineacion;