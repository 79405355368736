import React from "react";
import { StyledSection2YogaFondo, StyledSection3Yoga, StyledSection3YogaImage, StyledSection3YogaImg1, StyledSection3YogaImg2, StyledSection3YogaImg3, StyledSection3YogaTexto, StyledSection3YogaTextoContainer, StyledSection3YogaTitle } from "./Section3Yoga.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section3Yoga = ({title, text, img}) => {
    return (
        <>
            <StyledSection2YogaFondo />
            <StyledSection3Yoga>
                <StyledSection3YogaImage src={img} alt="Yoga" />
                <StyledSection3YogaTextoContainer>
                    <StyledSection3YogaTitle>
                        <Title color="white" size="xs">{title}</Title>
                    </StyledSection3YogaTitle>
                    <StyledSection3YogaTexto>
                        <Paragraph color="white" size="lg">{text}</Paragraph>
                    </StyledSection3YogaTexto>
                </StyledSection3YogaTextoContainer>
                <StyledSection3YogaImg1 src="img/Ellipse 28.svg" alt="Yoga1" />
                <StyledSection3YogaImg2 src="img/Group 40.svg" alt="Yoga2" />
                <StyledSection3YogaImg3 src="img/Group 55.svg" alt="Yoga3" />
            </StyledSection3Yoga>
        </>
    );
}

export default Section3Yoga;