import { styled } from "../../../theme/styled";

export const StyledSection2Meditacion = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto 1000px auto",
    position: "relative",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
        margin: "0 auto 800px auto",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
        margin: "0 auto 600px auto",
    },
    "@media (max-width: 720px)": {
        maxWidth: "400px",
        margin: "0 auto 460px auto",
    },
})

export const StyledSection2MeditacionFondo = styled("div", {
    position: "absolute",
    height: "700px",
    left: "0",
    right: "0",
    backgroundColor: "$lightgrey",
    "@media (max-width: 720px)": {
        height: "600px",
    },
})

export const StyledSection2MeditacionTexto = styled("div", {
    width: "380px",
    padding: "40px 40px 120px 40px",
    backgroundColor: "$white",
    marginTop: "60px",
    "@media (max-width: 1200px)": {
        width: "280px",
        padding: "40px 40px 80px 40px",
        marginTop: "10px",
    },
    "@media (max-width: 900px)": {
        width: "380px",
        padding: "40px 40px 60px 40px",
        marginTop: "10px",
    },
    "@media (max-width: 720px)": {
        width: "266px",
        padding: "40px 40px 40px 40px",
        marginTop: "10px",
    },
})

export const StyledSection2MeditacionImage1 = styled("img", {
    width: "310px",
    height: "310px",
    position: "absolute",
    top: "460px",
    left: "200px",
    "@media (max-width: 1200px)": {
        width: "250px",
        height: "250px",
        top: "480px",
        left: "150px",
    },
    "@media (max-width: 900px)": {
        width: "200px",
        height: "200px",
        top: "380px",
        left: "0px",
    },
    "@media (max-width: 720px)": {
        width: "100px",
        height: "100px",
        top: "420px",
        left: "4px",
    },
})

export const StyledSection2MeditacionImage2 = styled("img", {
    width: "790px",
    height: "790px",
    borderRadius: "50%",
    position: "absolute",
    top: "480px",
    border: "6px solid $light",
    "@media (max-width: 1200px)": {
        width: "630px",
        height: "630px",
        top: "500px",
    },
    "@media (max-width: 900px)": {
        width: "510px",
        height: "510px",
        top: "380px",
    },
    "@media (max-width: 720px)": {
        width: "320px",
        height: "320px",
        top: "430px",
    },
})

export const StyledSection2MeditacionImage3 = styled("img", {
    width: "230px",
    height: "230px",
    position: "absolute",
    top: "1050px",
    left: "200px",
    "@media (max-width: 1200px)": {
        width: "190px",
        height: "190px",
        top: "950px",
        left: "150px",
    },
    "@media (max-width: 900px)": {
        width: "150px",
        height: "150px",
        top: "780px",
        left: "40px",
    },
    "@media (max-width: 720px)": {
        width: "100px",
        height: "100px",
        top: "660px",
        left: "20px",
    },
})

export const StyledSection2MeditacionImage4 = styled("img", {
    width: "380px",
    height: "380px",
    borderRadius: "50%",
    border: "6px solid $light",
    position: "absolute",
    top: "1050px",
    right: "100px",
    "@media (max-width: 1200px)": {
        width: "310px",
        height: "310px",
        top: "950px",
        right: "50px",
    },
    "@media (max-width: 900px)": {
        width: "250px",
        height: "250px",
        top: "720px",
        right: "0px",
    },
    "@media (max-width: 720px)": {
        width: "190px",
        height: "190px",
        top: "660px",
        right: "10px",
    },
})