import {
    StyledButtons,
    StyledSectionPost,
    StyledSectionPostContent,
    StyledSectionPostTitle,
} from "./SectionPost.styles";

const SectionPost = StyledSectionPost;

SectionPost.Title = StyledSectionPostTitle;

SectionPost.Content = StyledSectionPostContent;

SectionPost.Buttons = StyledButtons;

export default SectionPost;
