import { styled } from "../../theme/styled";

export const StyledButton = styled("button", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "200px",
    height: "50px",
    backgroundColor: "$dark",
    color: "$light",
    borderRadius: "12px",
    border: "none",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: "18px",
    "&:hover": {
        backgroundColor: "$grey",
    },
    "&:active": {
        transform: "scale(0.95)",
    },
    "&:focus": {
        outline: "none",
    },
    "@media (max-width: 720px)": {
        width: "150px",
        height: "40px",
        fontSize: "16px",
        backgroundColor: "$grey",
        color: "$white",
    },
});