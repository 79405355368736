import { keyframes, styled } from "../../../theme/styled";

export const StyledSection2Home = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  "@media screen and (max-width: 1200px)": {
    maxWidth: "1200px",
  },
});

const appearAnimationLeft = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-100px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const appearAnimationRight = keyframes({
  '0%': { opacity: 0, transform: 'translateX(100px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const appearAnimationMobile = keyframes({
  '0%': { opacity: 0},
  '100%': { opacity: 1},
});

export const StyledSection1HomeCardLeft = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  animation: `${appearAnimationLeft} 1s ease forwards`,
  '@media (max-width: 900px)': {
    justifyContent: 'center',
  },
  "@media (max-width: 768px)": {
    animation: `${appearAnimationMobile} 1s ease forwards`,
  },
  variants: {
    isVisible: {
      true: {
        animation: `${appearAnimationLeft} 1s ease forwards`,
        "@media (max-width: 768px)": {
          animation: `${appearAnimationMobile} 1s ease forwards`,
        },
      },
      false: {
        animation: 'none',
      },
    },
  },
});

export const StyledSection1HomeCardRight = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginRight: '450px',
  animation: `${appearAnimationRight} 1s ease forwards`,
  '@media (max-width: 900px)': {
    justifyContent: 'center',
    marginRight: '0',
  },
  "@media (max-width: 768px)": {
    marginRight: '0',
    animation: `${appearAnimationMobile} 1s ease forwards`,
  },
  variants: {
    isVisible: {
      true: {
        animation: `${appearAnimationRight} 1s ease forwards`,
        "@media (max-width: 768px)": {
          animation: `${appearAnimationMobile} 1s ease forwards`,
        },
      },
      false: {
        animation: 'none',
      },
    },
  },
});

export const StyledSection1HomeTitle = styled('div', {
  margin: '80px 0',
  '@media (max-width: 720px)': {
    margin: '40px 0',
  },
});