import { keyframes, styled } from "../../../theme/styled";

export const StyledSection1Home = styled("div", {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "800px",
    "@media (max-width: 720px)": {
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        paddingTop: "20px",
    },
});

export const StyledSection1HomeFondo = styled("div", {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
});


export const StyledSection1HomeTitle = styled("div", {
    position: "absolute",
    top: "60px",
    left: "50px",
    zIndex: "1",
    "@media (max-width: 720px)": {
        position: "initial",
        textAlign: "center",
    },
    animation: `${keyframes({
        "0%": { opacity: 0 },
        "100%": { opacity: 1 },
    })} 0.5s ease-in-out`,
});

export const StyledSection1HomeSubtitle = styled("div", {
    position: "absolute",
    top: "180px",
    left: "55px",
    zIndex: "1",
    width: "380px",
    "@media (max-width: 900px)": {
        width: "300px",
        top: "120px",
    },
    "@media (max-width: 720px)": {
        position: "initial",
        textAlign: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderRadius: "10px",
        fontWeight: "400",
        marginBottom: "20px",
        padding: "10px",
    },
    animation: `${keyframes({
        "0%": { opacity: 0 },
        "100%": { opacity: 1 },
    })} 1s ease-in-out`,
    variants: {
        
    },
});

export const StyledSection1HomeButton = styled("div", {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    top: "420px",
    left: "55px",
    zIndex: "1",
    "@media (max-width: 900px)": {
        top: "360px",
    },
    "@media (max-width: 720px)": {
        position: "initial",
        textAlign: "center",
    },
    animation: `${keyframes({
        "0%": { opacity: 0 },
        "100%": { opacity: 1 },
    })} 1.2s ease-in-out`,
});
