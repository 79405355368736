import React from "react";
import Contact from "../components/Contact/Contact";
import Header from "../components/Header/Header";
import InicioTerapia from "../components/InicioTerapia/InicioTerapia";
import Section1Registros from "../components/SectionRegistrosAkashicos/Section1Registros/Section1Registros";
import Section2Registros from "../components/SectionRegistrosAkashicos/Section2Registros/Section2Registros";

const RegistrosAkashicos = () => {
    return (
        <>
            <Header />
            <InicioTerapia imagen="img/registros akashicos 1.png" terapia="Registros Akashicos" texto="La lectura de los registros akáshicos es una terapia que te permite acceder a la memoria de tu alma, donde se encuentra toda la información de tu pasado, presente y futuro. A través de esta conexión, podrás recibir la guía y el consejo que tu alma te ofrece para tu evolución y tu felicidad. La lectura de los registros akáshicos te ayudará a entender el origen de tus problemas, a sanar tus heridas, a liberar tus miedos, a descubrir tu misión y a vivir en armonía con tu esencia." />
            <Section1Registros />
            <Section2Registros />
            <Contact />
        </>
    );
};

export default RegistrosAkashicos;