import React from "react";
import {
    StyledSection1Home,
    StyledSection1HomeButton,
    StyledSection1HomeFondo,
    StyledSection1HomeSubtitle,
    StyledSection1HomeTitle,
} from "./Section1Home.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";
import Button from "../../Button/Button";

const Section1Home = () => {
    return (
        <StyledSection1Home>
            <StyledSection1HomeFondo>
                <img
                    src="img/home.jpg"
                    alt="home"
                    style={{
                        width: "100%",
                        height: "800px",
                        objectFit: "cover",
                        opacity: "0.9",
                        boxShadow: "0 10px 40px rgba(0, 0, 0, 0.2)",
                    }}
                />
            </StyledSection1HomeFondo>
            <StyledSection1HomeTitle>
                <Title size="lg" color="black">
                    Franco Bercia
                </Title>
            </StyledSection1HomeTitle>
            <StyledSection1HomeSubtitle>
                <Paragraph size="md" color="black">
                    Soy Franco, un terapeuta holístico certificado en Reiki
                    Usui, Registros Akáshicos y Yoga Prashant. Mi práctica se
                    enfoca en brindar sesiones de terapia que incluyen lecturas
                    de registros akáshicos, reiki, meditación y yoga. Con más de
                    5 años de experiencia, he concluido que estas terapias son
                    igualmente efectivas tanto en sesiones presenciales como
                    virtuales.
                </Paragraph>
            </StyledSection1HomeSubtitle>
            <StyledSection1HomeButton>
                <Button
                    onClick={() => {
                        window.open(
                            "https://www.instagram.com/recordandoterapias/",
                            "_blank"
                        );
                    }}
                >
                    Instagram
                </Button>
            </StyledSection1HomeButton>
        </StyledSection1Home>
    );
};

export default Section1Home;
