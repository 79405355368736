import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase-config";
import Header from "../components/Header/Header";
import Title from "../components/Title/Title";
import SectionPost from "../components/SectionPost/SectionPost";
import Loading from "../components/Loading/Loading";
import {
    StyledButtonText,
    StyledDeleteButtonIcon,
    StyledEditButtonContainer,
    StyledEditButtonIcon,
} from "../components/SectionPost/SectionPost.styles";

const Post = () => {
    const { id } = useParams();
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const isAuth = localStorage.getItem("isAuth");

    const deletePost = async (id) => {
        const postDoc = doc(db, "posts", id);
        await deleteDoc(postDoc);
    };

    useEffect(() => {
        const getPosts = async () => {
            const posts = await getDocs(collection(db, "posts"));
            setPost(
                posts.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }))
                    .filter((post) => post.id === id)[0]
            );
            setLoading(false);
        };
        getPosts();
    }, [id]);

    return (
        <SectionPost>
            <Header />
            {loading ? (
                <Loading visible={loading} />
            ) : (
                <>
                    {isAuth && (
                        <SectionPost.Buttons>
                            <StyledEditButtonContainer
                                onClick={() => navigate(`/editar/${post.id}`)}
                            >
                                <StyledEditButtonIcon />
                                <StyledButtonText>Editar</StyledButtonText>
                            </StyledEditButtonContainer>
                            <StyledEditButtonContainer
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            "¿Estás seguro de que quieres borrar este post?"
                                        )
                                    ) {
                                        deletePost(post.id);
                                        navigate("/blog");
                                    }
                                }}
                            >
                                <StyledDeleteButtonIcon />
                                <StyledButtonText>Borrar</StyledButtonText>
                            </StyledEditButtonContainer>
                        </SectionPost.Buttons>
                    )}

                    <SectionPost.Title>
                        <Title size="md" color="dark">
                            {post.title}
                        </Title>
                    </SectionPost.Title>
                    <SectionPost.Content
                        dangerouslySetInnerHTML={{
                            __html: post.value,
                        }}
                    />
                </>
            )}
        </SectionPost>
    );
};

export default Post;
