import {
    StyledSectionBlog,
    StyledSectionBlogButton,
    StyledSectionBlogImage,
    StyledSectionBlogPost,
    StyledSectionBlogPostContainer,
    StyledSectionBlogPostContent,
    StyledSectionBlogPostDate,
    StyledSectionBlogPostDeleteButton,
    StyledSectionBlogPostTitle,
    StyledSectionBlogPosts,
    StyledSectionBlogSubtitle,
    StyledSectionBlogTitle,
} from "./SectionBlog.styles";

const SectionBlog = StyledSectionBlog;

SectionBlog.Title = StyledSectionBlogTitle;

SectionBlog.Image = StyledSectionBlogImage;

SectionBlog.Subtitle = StyledSectionBlogSubtitle;

//posts

SectionBlog.Posts = StyledSectionBlogPosts;

SectionBlog.PostContainer = StyledSectionBlogPostContainer;

SectionBlog.Post = StyledSectionBlogPost;

SectionBlog.PostTitle = StyledSectionBlogPostTitle;

SectionBlog.PostDate = StyledSectionBlogPostDate;

SectionBlog.PostContent = StyledSectionBlogPostContent;

SectionBlog.Button = StyledSectionBlogButton;

SectionBlog.DeleteButton = StyledSectionBlogPostDeleteButton;

export default SectionBlog;
