import React, { useState } from "react";
import Header from "../components/Header/Header";
import { addDoc, collection } from "firebase/firestore";
import { db, storage } from "../firebase-config";
import { Editor } from "@tinymce/tinymce-react";
import AdminPost from "../components/SectionAdminBlog/SectionAdminBlog";
import Title from "../components/Title/Title";
import { useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const uploadImage = async (blob, filename) => {
    const fileExtension = filename.split(".").pop();
    const imageRef = ref(
        storage,
        "images/" +
            Math.random().toString(36).substring(2) +
            "." +
            fileExtension
    );
    const snapshot = await uploadBytes(imageRef, blob);

    return getDownloadURL(snapshot.ref);
};

const AdminBlog = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");

    const createPost = async () => {
        const post = {
            value: window.tinymce.activeEditor.getContent(),
            date: new Date(),
            title: title,
        };

        try {
            await addDoc(collection(db, "posts"), post);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <>
            <Header />
            <AdminPost>
                <AdminPost.Title>
                    <Title size="md" color="black">
                        Crear post
                    </Title>
                </AdminPost.Title>
                <AdminPost.Container>
                    <AdminPost.TitlePost
                        type="text"
                        onChange={(event) => setTitle(event.target.value)}
                        placeholder="Título del post"
                    />
                    <AdminPost.Button
                        onClick={(event) => {
                            if (!title) {
                                alert("El título es requerido");
                                return;
                            } else if (
                                !window.tinymce.activeEditor.getContent()
                            ) {
                                alert("El contenido es requerido");
                                return;
                            }
                            event.preventDefault();
                            createPost();
                            navigate("/blog");
                        }}
                    >
                        Publicar
                    </AdminPost.Button>
                </AdminPost.Container>
                <Editor
                    init={{
                        promotion: false,
                        min_height: 680,
                        language: "es",
                        language_url: "/langs/es.js",
                        plugins:
                            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                        image_uploadtab: true,
                        images_upload_handler: ({ blob, filename }) =>
                            uploadImage(blob(), filename()),
                    }}
                    initialValue="Welcome to TinyMCE!"
                    tinymceScriptSrc={
                        "https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.3/tinymce.min.js"
                    }
                />
            </AdminPost>
        </>
    );
};

export default AdminBlog;
