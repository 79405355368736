import React from "react";
import { StyledCardButton, StyledCardImage, StyledCardParagraph, StyledCardTerapia, StyledCardText } from "./CardTerapia.styles";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";
import Button from "../Button/Button";

const CardTerapia = ({ terapia, img, texto, onClick }) => {
    return (
        <StyledCardTerapia>
            <StyledCardImage src={img} alt="imgCard" />
            <StyledCardText>
                <Title size="md" color="black">{terapia}</Title>
                <StyledCardParagraph>
                    <Paragraph color="dark" size="sm">{texto}</Paragraph>
                </StyledCardParagraph>
                <StyledCardButton>
                    <Button onClick={onClick}>Ver más</Button>
                </StyledCardButton>
            </StyledCardText>
        </StyledCardTerapia>
    );
};

export default CardTerapia;