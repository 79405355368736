import { styled } from "../../../theme/styled";

export const StyledSection2Chakras = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    margin: "40px auto 200px auto",
    height: "600px",
    maxWidth: "1200px",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 720px)": {
        maxWidth: "600px",
        margin: "40px auto 420px auto",
    },    
})

export const StyledSection2ChakrasTexto = styled("div", {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "$lighter",
    zIndex: "1",
    width: "450px",
    padding: "60px 40px",
    borderRadius: "16px",
    position: "absolute",
    right: "260px",
    top: "300px",
    "@media (max-width: 1200px)": {
        right: "120px",
    },
    "@media (max-width: 720px)": {
        width: "260px",
        padding: "40px 40px",
        top: "240px",
        right: "50%",
        transform: "translateX(50%)",
    },
})

export const StyledSection2ChakrasImage1 = styled("img", {
    width: "480px",
    zIndex: "1",
    position: "absolute",
    top: "0",
    left: "200px",
    "@media (max-width: 1200px)": {
        left: "60px",
    },
    "@media (max-width: 720px)": {
        width: "320px",
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
    },
})

export const StyledSection2ChakrasImage2 = styled("img", {
    width: "700px",
    position: "absolute",
    top: "100px",
    "@media (max-width: 720px)": {
        display: "none",
    },
})