import React from "react";
import Section1Home from "../components/SectionHome/Section1Home/Section1Home";
import Header from "../components/Header/Header";
import Section2Home from "../components/SectionHome/Section2Home/Section2Home";
import Contact from "../components/Contact/Contact";

const Home = () => {
  return (
    <>
    <Header />
    <Section1Home />
    <Section2Home />
    <Contact />
    </>
  );
};

export default Home;