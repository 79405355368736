import React from "react";
import Header from "../components/Header/Header";
import InicioTerapia from "../components/InicioTerapia/InicioTerapia";
import Section1Reiki from "../components/SectionReiki/Section1Reiki/Section1Reiki";
import Section2Reiki from "../components/SectionReiki/Section2Reiki/Section2Reiki";
import Contact from "../components/Contact/Contact";

const Reiki = () => {
    return (
        <>
            <Header />
            <InicioTerapia terapia="Reiki" imagen="img/reiki 3.png" texto='El Reiki Usui, desarrollado por Mikao Usui en Japón en el siglo XX, es una técnica de sanación energética que canaliza la energía vital ("Ki" o "Chi") a través del cuerpo mediante la imposición de manos. Se centra en equilibrar y armonizar los aspectos físicos, emocionales, mentales y espirituales, promoviendo la relajación, reduciendo el estrés, el dolor y estimulando la autocuración del cuerpo.' />
            <Section1Reiki title="Los Beneficios Transformadores del Reiki: Cuerpo, Mente y Espíritu" image1="img/Reiki.png" image2="img/Reiki 1.png" image3="img/reiki 2.png" />
            <Section2Reiki title="Renueva tu Ser, Encuentra la Calma" image="img/reiki 4.png" texto="La sesión de Reiki a distancia implica enviar energía sanadora utilizando símbolos sagrados de Reiki, como Cho Ku Rei, Sei He Ki y Hon Sha Ze Sho Nen, que permiten conectarse con la persona receptora y superar las barreras de espacio y tiempo. Para realizarla, se necesita consentimiento del receptor y datos como nombre completo y fecha de nacimiento. Durante la sesión de 15 a 30 minutos, se trazan los símbolos sobre el nombre del receptor y se envía la energía necesaria para su bienestar. Se recomienda relajarse y estar receptivo durante la sesión. Al finalizar, se recibirá un mensaje para compartir cómo se ha sentido. Esta terapia efectiva y segura puede mejorar la salud física, mental y emocional del receptor." />
            <Contact />
        </>
    );
};

export default Reiki;