import React from "react";
import { StyledSection2Meditacion, StyledSection2MeditacionFondo, StyledSection2MeditacionImage1, StyledSection2MeditacionImage2, StyledSection2MeditacionImage3, StyledSection2MeditacionImage4, StyledSection2MeditacionTexto } from "./Section2Meditacion.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section2Meditacion = () => {
    return (
        <>
            <StyledSection2MeditacionFondo />
            <StyledSection2Meditacion>
                <StyledSection2MeditacionTexto>
                    <Title color="black" size="xs">Sesiones de Meditación Online a Distancia</Title>
                    <Paragraph color="dark" size="sm">Una sesión de meditación online a distancia es una forma de meditar con la ayuda de un instructor o una plataforma que ofrece recursos para facilitar la práctica. Se puede acceder a una sesión de meditación online a distancia desde cualquier dispositivo con conexión a internet, como un ordenador, un teléfono móvil o una tableta. La sesión puede ser en vivo o grabada, individual o grupal, y tener una duración variable según las preferencias y necesidades de cada persona. Habrá cientos de meditaciones diferentes y podrás elegir la que más te convenga.</Paragraph>
                </StyledSection2MeditacionTexto>
                <StyledSection2MeditacionImage1 src="img/Group 23.svg" alt="meditacion1" />
                <StyledSection2MeditacionImage2 src="img/meditacion 4.png" alt="meditacion2"/>
                <StyledSection2MeditacionImage3 src="img/Ellipse 28.svg" alt="meditacion3" />
                <StyledSection2MeditacionImage4 src="img/meditacion 5.png" alt="meditacion4" />
            </StyledSection2Meditacion>
        </>
    );
}

export default Section2Meditacion;