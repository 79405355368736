import { styled } from "../../theme/styled";

export const StyledSectionEditar = styled("div", {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1200px",
    margin: "0 auto",
    "@media (max-width: 1276px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
    },
    "@media (max-width: 720px)": {
        maxWidth: "324px",
        marginBottom: "40px",
    },
});

export const StyledSectionEditarTitle = styled("input", {
    padding: "10px",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    marginBottom: "20px",
    "&:focus": {
        outline: "none",
    },
    "@media (max-width: 720px)": {
        maxWidth: "300px",
    
    },
});

export const StyledSectionEditarButtonTitle = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "500px",
    justifyContent: "flex-start",
    "@media (max-width: 720px)": {
        marginBottom: "0",
        width: "100%",
        textAlign: "center",
    },
    "& button": {
        width: "100px",
        height: "40px",
        backgroundColor: "$dark",
        color: "$light",
        borderRadius: "12px",
        border: "none",
        cursor: "pointer",
        transition: "0.3s",
        fontSize: "16px",
        "&:hover": {
            backgroundColor: "$grey",
        },
        "&:active": {
            transform: "scale(0.95)",
        },
        "&:focus": {
            outline: "none",
        },
    },
});

export const StyledSectionEditarContent = styled("div", {});
