import { styled } from "../../../theme/styled";

export const StyledSection2Registros = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    maxWidth: "1200px",
    margin: "0 auto",
    paddingTop: "100px",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
    },
    "@media (max-width: 720px)": {
        maxWidth: "400px",
    },
})

export const StyledSection2RegistrosText = styled("div", {
    textAlign: "center",
    width: "660px",
    margin: "50px 0",
    display: "flex",
    gap: "40px",
    flexDirection: "column",
    "@media (max-width: 1200px)": {
        width: "500px",
    },
    "@media (max-width: 720px)": {
        width: "300px",
    },
})

export const StyledSection2RegistrosImage1 = styled("img", {
    width: "180px",
    position: "absolute",
    top: "240px",
    right: "220px",
    "@media (max-width: 1200px)": {
        right: "140px",
        width: "150px",
    },
    "@media (max-width: 900px)": {
        right: "60px",
        width: "100px",
    },
    "@media (max-width: 720px)": {
        right: "10px",
        width: "80px",
        top: "180px",
    },
})

export const StyledSection2RegistrosImage2 = styled("img", {
    width: "600px",
    borderRadius: "50%",
    "@media (max-width: 1200px)": {
        width: "500px",
    },
    "@media (max-width: 900px)": {
        width: "400px",
    },
    "@media (max-width: 720px)": {
        width: "300px",
    },
    
})

export const StyledSection2RegistrosImage3 = styled("img", {
    width: "200px",
    position: "absolute",
    top: "60px",
    left: "140px",
    "@media (max-width: 1200px)": {
        left: "100px",
        top: "80px",
        width: "150px",
    },
    "@media (max-width: 900px)": {
        left: "60px",
        top: "60px",
        width: "100px",
    },
    "@media (max-width: 720px)": {
        left: "40px",
        top: "40px",
        width: "80px",
    },
})