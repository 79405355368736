import React from "react";
import {
    StyledButtonInstagram,
    StyledButtonInstagramIcon,
    StyledButtonInstagramText,
} from "./ButtonInstagram.styles";

const ButtonInstagram = ({ onClick }) => {
    return (
        <StyledButtonInstagram onClick={onClick}>
            <StyledButtonInstagramIcon
                src="img/instagram.png"
                alt="instagram"
            />
            <StyledButtonInstagramText>Instagram</StyledButtonInstagramText>
        </StyledButtonInstagram>
    );
};

export default ButtonInstagram;
