import React from "react";
import { StyledInicioTerapia, StyledInicioTerapiaImage, StyledInicioTerapiaTextContainer } from "./InicioTerapia.styles";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";

const InicioTerapia = ({terapia, texto, imagen}) => {
    return (
        <StyledInicioTerapia>
            <StyledInicioTerapiaTextContainer>
                <Title size="lg" color="black">{terapia}</Title>
                    <Paragraph size="md" color="dark">
                        {texto}
                    </Paragraph>
            </StyledInicioTerapiaTextContainer>
            <StyledInicioTerapiaImage src={imagen} alt="Reiki" />
        </StyledInicioTerapia>
    );
}

export default InicioTerapia;