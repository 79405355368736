import { styled } from "../../../theme/styled";

export const StyledSection1Chakras = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "$light",
    "@media (max-width: 720px)": {
        flexDirection: "column",
    },
})

export const StyledSection1ChakrasTexto = styled("div", {
    width:"440px",
    marginTop: "320px",
    marginRight: "80px",
    "@media (max-width: 1200px)": {
        width: "380px",
        marginTop: "200px",
    },
    "@media (max-width: 720px)": {
        width: "300px",
        marginTop: "20px",
        textAlign: "center",
        marginRight: "0",
    },
})

export const StyledSection1ChakrasImageFondo = styled("div", {
    backgroundColor: "$orange2",
    borderRadius: "50%",
    width: "390px",
    height: "390px",
    position: "relative",
    margin: "40px 0 320px 0",
    "@media (max-width: 1200px)": {
        width: "300px",
        height: "300px",
    },
    "@media (max-width: 720px)": {
        width: "200px",
        height: "200px",
        margin: "40px auto 60px auto",
    },
})

export const StyledSection1ChakrasImage = styled("img", {
    borderRadius: "50%",
    width: "400px",
    height: "400px",
    position: "absolute",
    top: "20%",
    right: "20%",
    border: "3px solid $light",
    "@media (max-width: 1200px)": {
        width: "300px",
        height: "300px",
        top: "20%",
        right: "20%",
    },
    "@media (max-width: 720px)": {
        width: "200px",
        height: "200px",
        top: "20%",
        right: "20%",
    },
})