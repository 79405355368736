import {
    StyledSectionEditar,
    StyledSectionEditarButtonTitle,
    StyledSectionEditarContent,
    StyledSectionEditarTitle,
} from "./SectionEditar.styles";

const SectionEditar = StyledSectionEditar;

SectionEditar.Title = StyledSectionEditarTitle;

SectionEditar.Content = StyledSectionEditarContent;

SectionEditar.ButtonTitle = StyledSectionEditarButtonTitle;

export default SectionEditar;
