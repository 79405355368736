import { styled } from "../../../theme/styled";

export const StyledSection1Reiki = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "$lightgrey",
    "@media (max-width: 1200px)": {
        flexDirection: "column",
        alignItems: "center",
    },
});

export const StyledReikiTextContainer = styled("div", {
    width: "600px",
    marginRight: "80px",
    marginTop: "50px",
    "@media (max-width: 1200px)": {
        textAlign: "center",
        width: "600px",
        marginRight: "0",
    },
    "@media (max-width: 720px)": {
        width: "300px",
        marginTop: "20px",
    },
});

export const StyledReikiTitle = styled("div", {
    marginTop: "40px",
    "@media (max-width: 1200px)": {
        textAlign: "center",
        margin: "20px 0",
    },
});

export const StyledReikiParagraph = styled("div", {
    width: "650px",
    "@media (max-width: 1200px)": {
        textAlign: "center",
        width: "600px",
        margin: "0 auto",
    },
    "@media (max-width: 720px)": {
        width: "300px",
    },
});

export const StyledReikiImageContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "end",
    margin: "50px 0",
    position: "relative",
    gap: "40px",
    "@media (max-width: 1200px)": {
        alignItems: "center",
        margin: "20px 0",
    },
});

export const StyledReikiImage1 = styled("img", {
    width: "280px",
    "@media (max-width: 1200px)": {
        display: "none",
    },
});

export const StyledReikiImage2 = styled("img", {
    width: "395px",
    "@media (max-width: 1200px)": {
        width: "300px",

    },
});

export const StyledReikiImage3 = styled("img", {
    position: "absolute",
    bottom: "0",
    left: "-500px",
    width: "250px",
    "@media (max-width: 1200px)": {
        display: "none",
    },
});