import React from "react";
import InicioTerapia from "../components/InicioTerapia/InicioTerapia";
import Header from "../components/Header/Header";
import Contact from "../components/Contact/Contact";
import Section1Meditacion from "../components/SectionMeditacion/Section1Meditacion/Section1Meditacion";
import Section2Meditacion from "../components/SectionMeditacion/Section2Meditacion/Section2Meditacion";

const Meditacion = () => {
    return (
        <>
            <Header />
            <InicioTerapia imagen="img/meditacion 1.png" terapia="Meditación" texto="La meditación es una práctica que consiste en concentrar la atención en un objeto, un pensamiento, una sensación o la respiración, con el fin de alcanzar un estado de relajación, paz y armonía interior. La meditación tiene muchos beneficios para la salud física y mental, como reducir el estrés, mejorar el sistema inmunológico, aumentar la atención, la memoria y la creatividad, y favorecer el crecimiento espiritual." />
            <Section1Meditacion />
            <Section2Meditacion />
            <Contact />
        </>
    );
};

export default Meditacion;