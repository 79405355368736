import { styled } from "../../../theme/styled";

export const StyledSection1Yoga = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "100px auto",
    maxWidth: "1000px",
    "@media (max-width: 1080px)": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: "50px auto",
        gap: "40px",
    },
    "@media (max-width: 720px)": {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "50px auto",
        gap: "40px",
    },
});