import React from "react";
import {
    StyledHeader,
    StyledHeaderItem,
    StyledHeaderItemTerapia,
    StyledHeaderOptionTerapia,
    StyledHeaderOptionTerapiaItem,
} from "./Header.styles";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import ButtonMenu from "../ButtonMenu/ButtonMenu";

const Header = () => {
    const navigate = useNavigate();
    const isAuth = localStorage.getItem("isAuth");

    const singUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear();
        });
    };

    return (
        <StyledHeader>
            <ButtonMenu isAuth={isAuth} />
            <StyledHeaderItem onClick={() => navigate("/")}>
                Inicio
            </StyledHeaderItem>
            <StyledHeaderItemTerapia>
                Terapias
                <StyledHeaderOptionTerapia>
                    <StyledHeaderOptionTerapiaItem
                        onClick={() => navigate("/registros-akashicos")}
                    >
                        Registros akashicos
                    </StyledHeaderOptionTerapiaItem>
                    <StyledHeaderOptionTerapiaItem
                        onClick={() => navigate("/meditacion")}
                    >
                        Meditación
                    </StyledHeaderOptionTerapiaItem>
                    <StyledHeaderOptionTerapiaItem
                        onClick={() => navigate("/yoga")}
                    >
                        Yoga
                    </StyledHeaderOptionTerapiaItem>
                    <StyledHeaderOptionTerapiaItem
                        onClick={() => navigate("/reiki")}
                    >
                        Reiki
                    </StyledHeaderOptionTerapiaItem>
                    <StyledHeaderOptionTerapiaItem
                        onClick={() => navigate("/alineacion-de-chakras")}
                    >
                        Alineación de chakras
                    </StyledHeaderOptionTerapiaItem>
                </StyledHeaderOptionTerapia>
            </StyledHeaderItemTerapia>
            {isAuth ? null : (
                <StyledHeaderItem onClick={() => navigate("/blog")}>
                    Blog
                </StyledHeaderItem>
            )}
            {isAuth ? (
                <StyledHeaderItemTerapia>
                    Administracion
                    <StyledHeaderOptionTerapia>
                        <StyledHeaderOptionTerapiaItem
                            onClick={() => navigate("/blog")}
                        >
                            Blog
                        </StyledHeaderOptionTerapiaItem>
                        <StyledHeaderOptionTerapiaItem
                            onClick={() => navigate("/admin-blog")}
                        >
                            Crear post
                        </StyledHeaderOptionTerapiaItem>
                        <StyledHeaderOptionTerapiaItem
                            onClick={() => {
                                singUserOut();
                                navigate("/login");
                            }}
                        >
                            Cerrar sesion
                        </StyledHeaderOptionTerapiaItem>
                    </StyledHeaderOptionTerapia>
                </StyledHeaderItemTerapia>
            ) : null}
            <StyledHeaderItem
                onClick={() => {
                    const element = document.querySelector("#contact");
                    if (element) {
                        window.scrollTo({
                            top: element.offsetTop,
                            behavior: "smooth",
                        });
                    } else {
                        navigate("/");
                        setTimeout(() => {
                            window.scrollTo({
                                top: document.querySelector("#contact")
                                    .offsetTop,
                                behavior: "smooth",
                            });
                        }, 0);
                    }
                }}
            >
                Contacto
            </StyledHeaderItem>
        </StyledHeader>
    );
};

export default Header;
