import React from "react";
import { StyledSection1Meditacion, StyledSection1MeditacionImage1, StyledSection1MeditacionImage2, StyledSection1MeditacionTextoContainer } from "./Section1Meditacion.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section1Meditacion = () => {
    return (
        <StyledSection1Meditacion>
            <StyledSection1MeditacionImage1 src="img/meditacion 2.png" alt="meditacion2" />
            <StyledSection1MeditacionImage2 src="img/meditacion 3.png" alt="meditacion3" />
            <StyledSection1MeditacionTextoContainer>
                <Title color="black" size="xs">La Práctica de la Meditación </Title>
                <Paragraph color="dark" size="sm">La meditación se puede practicar de forma individual o grupal, presencial o a distancia. Para meditar se necesita un lugar tranquilo, ropa cómoda y una postura adecuada. Se puede meditar siguiendo una guía, una música, un mantra o simplemente la propia respiración. Lo importante es mantener la mente enfocada y libre de distracciones.</Paragraph>
            </StyledSection1MeditacionTextoContainer>
        </StyledSection1Meditacion>
    );
};

export default Section1Meditacion;