import { styled } from "../../theme/styled";

export const StyledSectionBlog = styled("div", {
    margin: "40px auto",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    "@media (max-width: 720px)": {
        margin: "0 0 20px 0",
        gap: "0",
    },
});

export const StyledSectionBlogTitle = styled("div", {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "-18px",
    marginLeft: "20px",
    "@media (max-width: 720px)": {
        justifyContent: "center",
        textAlign: "center",
        marginBottom: "0",
    },
});

export const StyledSectionBlogImage = styled("img", {
    height: "600px",
    objectFit: "cover",
    width: "100%",
    "@media (max-width: 720px)": {
        height: "300px",
    },
});

export const StyledSectionBlogSubtitle = styled("div", {
    textAlign: "center",
    maxWidth: "80%",
    margin: "0 auto",
    "@media (max-width: 720px)": {
        maxWidth: "90%",
    },
});

//Posts

export const StyledSectionBlogPosts = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
    marginTop: "40px",
});

export const StyledSectionBlogPostContainer = styled("div", {
    padding: "20px",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "1200px",
    height: "260px",
    borderRadius: "16px",
    position: "relative",
    "@media (max-width: 1300px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 1000px)": {
        maxWidth: "700px",
    },
    "@media (max-width: 800px)": {
        maxWidth: "500px",
    },
    "@media (max-width: 600px)": {
        maxWidth: "300px",
    },
});

export const StyledSectionBlogPost = styled("div", {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
});

export const StyledSectionBlogPostTitle = styled("div", {
    "@media (max-width: 600px)": {
        maxWidth: "300px",
    },
});

export const StyledSectionBlogPostDate = styled("div", {});

export const StyledSectionBlogPostContent = styled("div", {
    height: "100px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "5",
    WebkitBoxOrient: "vertical",
    "@media (max-width: 600px)": {
        height: "120px",
    },
});

export const StyledSectionBlogButton = styled("button", {
    color: "$primary",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "16px",
    position: "absolute",
    bottom: "20px",
    left: "20px",
    cursor: "pointer",
    padding: "0",
});

export const StyledSectionBlogPostDeleteButton = styled("img", {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    transition: "transform 0.3s",
    position: "absolute",
    top: "20px",
    right: "20px",
    "&:hover": {
        transform: "scale(1.1)",
    },
});
