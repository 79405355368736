import React from "react";
import { StyledCardYoga, StyledCardYogaIcon } from "./CardYoga.styles";
import Paragraph from "../Paragraph/Paragraph";

const CardYoga = ({ icon, texto, color }) => {

    return (
        <StyledCardYoga color={color}>
            <StyledCardYogaIcon src={icon} alt="icon" />
            <Paragraph color="dark" size="sm">{texto}</Paragraph>
        </StyledCardYoga>
    );
};

export default CardYoga;