import { styled } from "../../theme/styled";

export const StyledSectionAdminBlog = styled("div", {
    maxWidth: "1200px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    gap: "20px",
    "@media (max-width: 1276px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
    },
    "@media (max-width: 720px)": {
        maxWidth: "324px",
    },
})

export const StyledSectionAdminBlogTitle = styled("div", {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
})

export const StyledSectionAdminBlogContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 720px)": {
        flexDirection: "column",
        gap: "20px",
        "& > *:last-child": {
            order: -1,
        }
    },
})

export const StyledSectionAdminBlogTitlePost = styled("input", {
    padding: "10px",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "400px",
    "&:focus": {
        outline: "none",
    },
    "@media (max-width: 720px)": {
        maxWidth: "300px",
    
    },
})

export const StyledSectionAdminBlogButton = styled("button", {
    padding: "10px 20px",
    borderRadius: "5px",
    width: "100px",
    backgroundColor: "$dark",
    color: "$white",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
        backgroundColor: "$grey",
    }
})