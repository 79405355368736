import React from "react";
import { StyledSection2Yoga, StyledSection2YogaFondo, StyledSection2YogaImage1, StyledSection2YogaImage2, StyledSection2YogaImage3, StyledSection2YogaImageContainer, StyledSection2YogaTexto1Container, StyledSection2YogaTexto2Container } from "./Section2Yoga.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section2Yoga = () => {
    return (
        <>
            <StyledSection2YogaFondo />
            <StyledSection2Yoga>
                <StyledSection2YogaTexto1Container>
                    <Paragraph color="dark" size="md">
                        <b>Hatha:</b> Tradicional, ideal para principiantes con una intensidad suave.<br /><br />

                        <b>Vinyasa:</b> Dinámico y popular, adaptable para todos los niveles con movimientos continuos.<br /><br />

                        <b>Ashtanga:</b> Secuencia fija, con intensidad media a alta para niveles intermedios.<br /><br />

                        <b>Yin:</b> Relajado y suave, útil para principiantes y embarazadas con posturas mantenidas.<br /><br />

                        <b>Rocket:</b> Dinámico y desafiante, para niveles intermedios o avanzados.<br /><br />

                        <b>Integral:</b> Integración física, mental y espiritual para niveles intermedios a avanzados.<br /><br />

                        <b>Jivamukti:</b> Combinación de posturas ashtanga con espiritualidad para niveles intermedios a avanzados.<br /><br />

                        <b>Power:</b> Alta intensidad basada en ashtanga para niveles intermedios a avanzados.<br /><br />

                        <b>Iyengar:</b> Enfoque en el alineamiento con soportes, para niveles intermedios con intensidad media.<br /><br />

                        <b>Yoga facial:</b> Ejercicios para estimular músculos faciales y mejorar la piel.<br /><br />

                        <b>Kundalini:</b> Espiritual y devocional, con el objetivo de activar los chakras y despertar la conciencia interior.<br /><br />

                        <b>Bikram:</b> Practicado en condiciones de alta temperatura y humedad para mayor flexibilidad.<br /><br />

                        <b>Sivananda:</b> Síntesis de caminos espirituales para comprensión del ser y del universo.
                    </Paragraph>
                </StyledSection2YogaTexto1Container>
                <StyledSection2YogaImageContainer>
                    <StyledSection2YogaImage1 src="img/yoga 4.png" alt="yoga1" />
                    <StyledSection2YogaImage2 src="img/yoga 5.png" alt="yoga2" />
                    <StyledSection2YogaImage3 src="img/yoga 3.png" alt="yoga3" />
                </StyledSection2YogaImageContainer>
                <StyledSection2YogaTexto2Container>
                    <Title color="black" size="xs">Explora el Mundo del Yoga: Descubre sus Diversos Caminos</Title>
                    <Paragraph color="dark" size="md">El yoga ofrece una amplia variedad de estilos que se adaptan a las preferencias y objetivos individuales de los practicantes, permitiendo diferentes enfoques para alcanzar la autorrealización y el crecimiento espiritual. Algunos de estos estilos incluyen:
                    </Paragraph>
                </StyledSection2YogaTexto2Container>
            </StyledSection2Yoga>
        </>
    );
};

export default Section2Yoga;