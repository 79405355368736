import { styled } from "../../theme/styled";

export const StyledSectionLogin = styled("div", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
});

export const StyledSectionLoginConteiner = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    width: "400px",
    shadow: 1,
    marginTop: "120px",
    position: "relative",
    borderRadius: "36px",
    padding: "40px",
    "@media (max-width: 720px)": {
        width: "280px",
    
    },
});

export const StyledSectionLoginTitle = styled("div", {
    margin: "80px 0",
    textAlign: "center",
});

export const StyledSectionLoginLogo = styled("img", {
    borderRadius: "50%",
    width: "160px",
    position: "absolute",
    top: "-80px",
});

export const StyledSectionLoginButtonContainer = styled("div", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    borderRadius: "24px",
    shadow: 1,
    padding: "12px 24px",
    cursor: "pointer",
    transition: "0.3s",
});

export const StyledSectionLoginButtonLogo = styled("img", {
    width: "32px",
    marginRight: "10px",
});

export const StyledSectionLoginButtonText = styled("span", {
    color: "$dark",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "0.3s",
});
