import { keyframes, styled } from "../../theme/styled";

const appearAnimationText = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  });

export const StyledCardYoga = styled("div", {
    width: "180px",
    textAlign: "center",
    padding: "20px",
    borderRadius: "16px",
    animation: `${appearAnimationText} 1s ease forwards`,
    variants: {
        color: {
            grey: {
                backgroundColor: "$grey",
            },
            lightGrey: {
                backgroundColor: "$lightgrey",
            },
            light: {
                backgroundColor: "$light",
            },
        },
    },
})

export const StyledCardYogaIcon = styled("img", {})