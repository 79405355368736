import { styled } from "../../theme/styled";

export const StyledParagraph = styled("p", {
    variants: {
        color: {
            dark: {
                color: "$dark",
            },
            white: {
                color: "$lighter",
            },
            grey: {
                color: "$grey",
            },
        },
        size: {
            sm: {
                fontSize: "16px",
                "@media (max-width: 1200px)": {
                    fontSize: "14px",
                },
            },
            md: {
                fontSize: "18px",
                "@media (max-width: 1200px)": {
                    fontSize: "16px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "14px",
                },
            },
            lg: {
                fontSize: "24px",
                "@media (max-width: 1200px)": {
                    fontSize: "20px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "16px",
                
                },
            },
            xl: {
                fontSize: "28px",
                "@media (max-width: 1200px)": {
                    fontSize: "24px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "20px",
                },
            },
            xxl: {
                fontSize: "30px",
                "@media (max-width: 1200px)": {
                    fontSize: "26px",
                },
                "@media (max-width: 720px)": {
                    fontSize: "22px",
                },
            }
        },
    },
});

