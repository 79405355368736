import React from "react";
import { StyledReikiImage1, StyledReikiImage2, StyledReikiImage3, StyledReikiImageContainer, StyledReikiParagraph, StyledReikiTextContainer, StyledReikiTitle, StyledSection1Reiki } from "./Section1Reiki.styles";
import Title from "../../Title/Title";
import Paragraph from "../../Paragraph/Paragraph";

const Section1Reiki = ({ title, image1, image2, image3 }) => {
    return (
        <StyledSection1Reiki>
            <StyledReikiTextContainer>
                <StyledReikiTitle><Title color="black" size="xs">{title}</Title></StyledReikiTitle>
                <StyledReikiParagraph><Paragraph color="dark" size="sm">
                    <b>Promueve la armonía y el equilibrio:</b> Restablece el equilibrio entre mente, cuerpo y espíritu, fomentando un estilo de vida positivo.<br />
                    <b>Relaja y libera la tensión del cuerpo:</b> Permite una sensación de calma y relajación, facilitando la conexión con el yo interior y la reflexión.<br />
                    <b>Desbloquea la energía y equilibra la mente, el cuerpo y el espíritu:</b> Favorece un flujo constante de energía, reduciendo el estrés y promoviendo una mayor energía positiva.<br />
                    <b>Limpia el cuerpo de toxinas y apoya el sistema inmunológico:</b> Facilita el proceso de autocuración y desencadena un estado de reparación.<br />
                    <b>Aclara la mente y mejora el enfoque:</b> Fomenta la concentración en el presente, reduciendo la ansiedad y promoviendo una actitud más positiva.<br />
                    <b>Mejora la calidad del sueño:</b> La relajación profunda facilita un sueño reparador y una mayor claridad mental.<br />
                    <b>Facilita el crecimiento espiritual y la limpieza emocional:</b> Dirigido a mente, cuerpo y espíritu, el Reiki puede sanar heridas emocionales y fortalecer las relaciones personales.<br />
                </Paragraph></StyledReikiParagraph>
            </StyledReikiTextContainer>
            <StyledReikiImageContainer>
                <StyledReikiImage1 src={image1} />
                <StyledReikiImage2 src={image2} />
                <StyledReikiImage3 src={image3} />
            </StyledReikiImageContainer>

        </StyledSection1Reiki>
    );
}

export default Section1Reiki;