import { styled } from "../../../theme/styled";

export const StyledSection1Meditacion = styled("div", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "1200px",
    position: "relative",
    margin: "60px auto 380px auto",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
        margin: "60px auto 500px auto",
    },
    "@media (max-width: 720px)": {
        maxWidth: "400px",
        margin: "60px auto 460px auto",
    },
});

export const StyledSection1MeditacionImage1 = styled("img", {
    width: "620px",
    height: "620px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "6px solid $lightgrey",
    zIndex: "1",
    "@media (max-width: 1200px)": {
        width: "500px",
        height: "500px",
    },
    "@media (max-width: 900px)": {
        width: "400px",
        height: "400px",
    },
    "@media (max-width: 720px)": {
        width: "300px",
        height: "300px",
    },
});

export const StyledSection1MeditacionImage2 = styled("img", {
    width: "372px",
    height: "372px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "6px solid $grey",
    position: "absolute",
    left: "180px",
    top: "450px",
    zIndex: "2",
    "@media (max-width: 1200px)": {
        width: "300px",
        height: "300px",
        left: "150px",
        top: "370px",
    },
    "@media (max-width: 900px)": {
        width: "240px",
        height: "240px",
        left: "36px",
        top: "300px",
    },
    "@media (max-width: 720px)": {
        width: "180px",
        height: "180px",
        left: "10px",
        top: "200px",
    },
});

export const StyledSection1MeditacionTextoContainer = styled("div", {
    backgroundColor: "$light",
    padding: "200px 40px 40px 40px",
    width: "290px",
    position: "absolute",
    top: "420px",
    right: "180px",
    "@media (max-width: 1200px)": {
        padding: "160px 40px 40px 40px",
        width: "230px",
        top: "340px",
        right: "150px",
    },
    "@media (max-width: 900px)": {
        padding: "120px 40px 40px 40px",
        width: "180px",
        top: "300px",
        right: "60px",
    },
    "@media (max-width: 720px)": {
        padding: "80px 20px 20px 40px",
        width: "180px",
        top: "300px",
        right: "20px",
    },
});