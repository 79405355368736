import React from "react";
import Header from "../components/Header/Header";
import Contact from "../components/Contact/Contact";
import CardYoga from "../components/CardYoga/CardYoga";
import Section1Yoga from "../components/SectionYoga/Section1Yoga/Section1Yoga";
import InicioTerapia from "../components/InicioTerapia/InicioTerapia";
import Section2Yoga from "../components/SectionYoga/Section2Yoga/Section2Yoga";
import Section3Yoga from "../components/SectionYoga/Section3Yoga/Section3Yoga";

const Yoga = () => {
    return (
        <>
            <Header />
            <InicioTerapia terapia="Yoga" imagen="img/yoga 1.png" texto="El yoga es una disciplina que nació en la India hace más de 5.000 años y que engloba toda una forma de vida. Esta práctica incluye ejercicio físico, respiración, meditación y hasta un manual de vida. Su fin último es alcanzar el samadhi o el estado de paz, nirvana, liberación y felicidad." />
            <Section1Yoga>
                <CardYoga icon="img/posicion-de-loto 1.svg" color="grey" texto="El yoga es muy popular por sus beneficios físicos y mentales, incluyendo fuerza, flexibilidad y calma interior." />
                <CardYoga icon="img/yoga_1 1.svg" color="lightGrey" texto='"Yoga" significa unión de cuerpo, mente y alma. Según el Yoga Sutra de Patanjali, detener la mente lleva a la paz interior y plenitud.' />
                <CardYoga icon="img/yoga-_1__1 1.svg" color="light" texto="Patanjali describe la práctica del yoga en 8 pasos, desde la conducta ética hasta el cese de la mente, incluyendo posturas, respiración y meditación." />
            </Section1Yoga>
            <Section3Yoga title="Mi Enfoque para una Clase Transformadora" img="img/yoga 2.jpg" text='Las clases pregrabadas durarán 40 minutos y se adaptarán a tu nivel. Practicaremos Yoga Prashant y Yoga Nidra. Yoga Prashant combina enseñanzas del hatha yoga con filosofía y psicología del yoga, creado por Prashant Iyengar. Se centra en el estudio profundo de textos clásicos del yoga y busca explorar la conciencia y la sensibilidad del practicante. Yoga Nidra, también conocido como "sueño yóguico", es una forma de meditación guiada que se realiza acostado.' />
            <Section2Yoga />
            <Contact />
        </>
    );
};

export default Yoga;