import { StyledSectionLogin, StyledSectionLoginButtonContainer, StyledSectionLoginButtonLogo, StyledSectionLoginButtonText, StyledSectionLoginConteiner, StyledSectionLoginLogo, StyledSectionLoginTitle } from "./SectionLogin.styles";

const SectionLogin = StyledSectionLogin;

SectionLogin.Conteiner = StyledSectionLoginConteiner;

SectionLogin.Title = StyledSectionLoginTitle;

SectionLogin.Logo = StyledSectionLoginLogo;

SectionLogin.Button = StyledSectionLoginButtonContainer;

SectionLogin.ButtonLogo = StyledSectionLoginButtonLogo;

SectionLogin.ButtonText = StyledSectionLoginButtonText;


export default SectionLogin;