import { createStitches } from "@stitches/react";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      red: "#f63737",
      green: "#91C301",
      blue: "#4B0BB4",
      yellow: "#FF912C",
      white: "#ffffff",
      primary: "#c2aa8c",
      brown: "#865333",
      brown2: "#623E2A",
      litebrown: "#E2D3BA",
      litebrown2: "#D1C1A9",
      orange: "#B7845F",
      orange2: "#FFAC70",
      lightorange: "#FFD0AC",
      dark: "#333333",
      grey: "#9AA2A9",
      lightgrey: "#CCD3DB",
      secondary: "#efebe5",
      light: "#E0E5EB",
      lighter: "#fafafa",
      whatsapp: "#25d366",
      disabled: "#666",
      active: "$primary",
      instagram: "#E1306C",
    },
  },
  utils: {
    marginX: (value) => ({ marginLeft: value, marginRight: value }),
    marginY: (value) => ({ marginTop: value, marginBottom: value }),
    paddingX: (value) => ({ paddingLeft: value, paddingRight: value }),
    paddingY: (value) => ({ paddingTop: value, paddingBottom: value }),
    shadow: (value) => {
      if (value === 1) {
        return { boxShadow: "0 1px 20px rgba(0, 0, 0, 0.1)" };
      } else if (value === 2) {
        return { boxShadow: "0 1px 53px rgba(0, 0, 0, 0.15)" };
      } else if (value === 3) {
        return { boxShadow: "0 0 100px rgba(0, 0, 0, 0.5)" };
      } else if (value === 4) {
        return { boxShadow: "0 8px 20px rgb(86, 86, 86, 0.5)" };
      }
    },
  },
});