import React from "react";
import { auth, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import SectionLogin from "../components/SectionLogin/SectionLogin";
import Title from "../components/Title/Title";

const Login = () => {
    const navigate = useNavigate();

    const singInWithGoogle = () => {
        signInWithPopup(auth, provider).then((result) => {
            localStorage.setItem("isAuth", true);
            navigate("/");
        });
    };
    return (
        <SectionLogin>
            <Header />
            <SectionLogin.Conteiner>
                <SectionLogin.Logo src="/img/Logo.jpeg" />
                <SectionLogin.Title>
                    <Title size="xs" color="black">Bienvenido a Recordando Terapias</Title>
                </SectionLogin.Title>
                <SectionLogin.Button onClick={singInWithGoogle}>
                    <SectionLogin.ButtonLogo src="/img/google.png" />
                    <SectionLogin.ButtonText>Iniciar sesion con Google</SectionLogin.ButtonText>
                </SectionLogin.Button>
            </SectionLogin.Conteiner>
        </SectionLogin>
    );
};

export default Login;
