import { styled } from "../../theme/styled";

export const StyledContact = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "$light",
    width: "100%",
    paddingTop: "30px",
});

export const StyledContactParagraph = styled("div", {
    textAlign: "center",
    width: "60%",
});

export const StyledContactButtons = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
    "@media (max-width: 720px)": {
        flexDirection: "column",
        gap: "0",
    },
});