import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import { Editor } from "@tinymce/tinymce-react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase-config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import SectionEditar from "../components/SectionEditar/SectionEditar";
import Button from "../components/Button/Button";

const uploadImage = async (blob, filename) => {
    const fileExtension = filename.split(".").pop();
    const imageRef = ref(
        storage,
        "images/" +
            Math.random().toString(36).substring(2) +
            "." +
            fileExtension
    );
    const snapshot = await uploadBytes(imageRef, blob);

    return getDownloadURL(snapshot.ref);
};

const Editar = () => {
    const { id } = useParams();
    const [post, setPost] = useState({});
    const navigate = useNavigate();
    const [title, setTitle] = useState("");

    const UpdatePost = async () => {
        const postDoc = doc(db, "posts", id);
        await updateDoc(postDoc, {
            value: window.tinymce.activeEditor.getContent(),
            title: title,
        });
    };

    useEffect(() => {
        const getPost = async () => {
            const post = await getDoc(doc(db, "posts", id));
            setPost(post.data());
            setTitle(post.data().title);
        };
        getPost();
    }, [id]);

    return (
        <>
            <Header />
            <SectionEditar>
                <SectionEditar.ButtonTitle>
                    <Button
                        onClick={() => {
                            UpdatePost();
                            navigate(`/blog/${id}`);
                        }}
                    >
                        Guardar
                    </Button>
                    <SectionEditar.Title
                        type="text"
                        placeholder="Título del post"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </SectionEditar.ButtonTitle>
                <Editor
                    init={{
                        promotion: false,
                        min_height: 680,
                        language: "es",
                        language_url: "/langs/es.js",
                        plugins:
                            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                        toolbar:
                            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                        image_uploadtab: true,
                        images_upload_handler: ({ blob, filename }) =>
                            uploadImage(blob(), filename()),
                    }}
                    initialValue={post.value}
                    tinymceScriptSrc={
                        "https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.3/tinymce.min.js"
                    }
                />
            </SectionEditar>
        </>
    );
};

export default Editar;
