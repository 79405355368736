import { styled } from "../../theme/styled";
import { IoIosMenu } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

export const StyledButtonMenu = styled("div", {
    display: "none",
    "@media (max-width: 720px)": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
});

export const StyledButtonMenuIcon = styled(IoIosMenu, {
    cursor: "pointer",
    fontSize: "36px",
    color: "$dark",
    marginRight: "20px",
});

export const StyledButtonMenuContent = styled("div", {
    variants: {
        open: {
            true: {
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "36px",
                right: "40px",
                backgroundColor: "$lighter",
                padding: "20px",
                width: "200px",
                borderRadius: "10px",
                zIndex: "10000000",
            },
            false: {
                display: "none",
            },
        },
    },
});

export const StyledMenuContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    variants: {
        open: {
            false: {
                borderBottom: "1px solid $light",
            },
        },
    },
});

export const StyledMenuContainerButton = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
        "& span, & svg": {
            color: "$primary",
        },
    },
});

export const StyledMenuTextButton = styled("span", {
    fontWeight: "400",
    color: "$dark",
    fontSize: "17px",
});

export const StyledMenuIconButton = styled(IoIosArrowDown, {
    cursor: "pointer",
    color: "$dark",
    transition: "transform 0.3s",
    variants: {
        open: {
            true: {
                transform: "rotate(180deg)",
            },
            false: {
                transform: "rotate(0deg)",
            },
        },
    },
});

export const StyledMenuContentButton = styled("div", {
    variants: {
        open: {
            true: {
                display: "flex",
                flexDirection: "column",
                marginLeft: "12px",
                "& button": {
                    fontSize: "14px",
                    fontWeight: "400",
                },
            },
            false: {
                display: "none",
            },
        },
    },
});

export const StyledButtonMenuContentLink = styled("button", {
    fontSize: "16px",
    color: "$dark",
    fontWeight: "400",
    cursor: "pointer",
    textDecoration: "none",
    border: "none",
    backgroundColor: "transparent",
    textAlign: "left",
    borderBottom: "1px solid $light",
    padding: "10px",
    display: "block",
    "&:last-child": {
        borderBottom: "none",
    },
    "&:hover": {
        color: "$primary",
    },
});
