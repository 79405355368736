import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAKrgr_h4k5eQ6KaGfdtFqYae7N84wyvwQ",
    authDomain: "franco-bercia.firebaseapp.com",
    projectId: "franco-bercia",
    storageBucket: "franco-bercia.appspot.com",
    messagingSenderId: "193986638151",
    appId: "1:193986638151:web:1aae3511642a13176cdc62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage()