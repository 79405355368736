import { styled } from "../../../theme/styled";

export const StyledSection2Yoga = styled("div", {
    maxWidth: "1200px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
    position: "relative",
    "@media (max-width: 720px)": {
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "300px",
        margin: "0 auto 1460px auto",
    },
});

export const StyledSection2YogaFondo = styled("div", {
    backgroundColor: "$lightgrey",
    height: "480px",
    position: "absolute",
    left: "0",
    right: "0",
    width: "100%",
    zIndex: "-1",
    "@media (max-width: 720px)": {
        height: "550px",
    },
});

export const StyledSection2YogaTexto1Container = styled("div", {
    width: "1080px",
    marginTop: "500px",
    marginBottom: "100px",
    marginLeft: "30px",
    "@media (max-width: 900px)": {
        width: "100%",
    },
    "@media (max-width: 720px)": {
        position: "absolute",
        top: "660px",
        width: "300px",
        margin: "0",
        textAlign: "center",
    },
});

export const StyledSection2YogaTexto2Container = styled("div", {
    width: "940px",
    margin: "60px 60px 0 0",
    "@media (max-width: 900px)": {
        margin: "60px 0 0 0",
    },
    "@media (max-width: 720px)": {
        position: "absolute",
        top: "150px",
        width: "300px",
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center",
    },
});

export const StyledSection2YogaImageContainer = styled("div", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    marginTop: "30px",
    "@media (max-width: 720px)": {
        maxWidth: "300px",
        margin:"0",
    },
});

export const StyledSection2YogaImage1 = styled("img", {
    borderRadius: "50%",
    width: "400px",
    height: "400px",
    position: "absolute",
    top: "0",
    right: "80%",
    "@media (max-width: 1400px)": {
        width: "320px",
        height: "320px",
    },
    "@media (max-width: 900px)": {
        width: "280px",
        height: "280px",
        right: "70%",
    },
    "@media (max-width: 720px)": {
        width: "180px",
        height: "180px",
        top: "16px",
        right: "50%",
        transform: "translateX(50%)",
    },
});

export const StyledSection2YogaImage2 = styled("img", {
    borderRadius: "50%",
    width: "400px",
    objectFit: "cover",
    position: "absolute",
    left: "30%",
    top: "400px",
    "@media (max-width: 1400px)": {
        width: "320px",
    },
    "@media (max-width: 900px)": {
        width: "280px",
        left: "20%",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledSection2YogaImage3 = styled("img", {
    borderRadius: "50%",
    width: "420px",
    objectFit: "cover",
    position: "absolute",
    left: "100%",
    top: "900px",
    "@media (max-width: 1400px)": {
        width: "320px",
    },
    "@media (max-width: 900px)": {
        width: "280px",
        left: "100%",
    },
    "@media (max-width: 720px)": {
        width: "200px",
        height: "200px",
        top: "460px",
        left: "20%",
    },
});