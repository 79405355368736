import { styled } from "../../theme/styled";

export const StyledHeader = styled("div", {
    display: "flex",
    alignItems: "center",
    height: "78px",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    backgroundColor: "$white",
    position: "relative",
    zIndex: "100",
    "@media (max-width: 1400px)": {
        gap: "20px",
    },
});

export const StyledHeaderItem = styled("span", {
    fontSize: "20px",
    color: "$dark",
    fontWeight: "500",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
        color: "$primary",
    },
    "@media (min-width: 1400px)": {
        marginRight: "20px",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledHeaderOptionTerapiaItem = styled("span", {
    fontSize: "20px",
    color: "$dark",
    fontWeight: "500",
    cursor: "pointer",
    borderBottom: "1px solid $light",
    padding: "10px",
    display: "block",
    "&:last-child": {
        borderBottom: "none",
    },
    "&:hover": {
        color: "$primary",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledHeaderOptionTerapia = styled("div", {
    display: "none",
    position: "absolute",
    top: "25px",
    right: "-80px",
    padding: "16px",
    width: "200px",
    backgroundColor: "$lighter",
    zIndex: "100",
    textAlign: "center",
    "&:hover": {
        display: "block",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

export const StyledHeaderItemTerapia = styled("a", {
    fontSize: "20px",
    color: "$dark",
    fontWeight: "500",
    cursor: "pointer",
    position: "relative",
    textDecoration: "none",
    "@media (min-width: 1400px)": {
        marginRight: "20px",
    },
    "&:hover": {
        color: "$primary",
        [`${StyledHeaderOptionTerapia}`]: {
            display: "block",
        },
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});
