import { styled } from "../../../theme/styled";

export const StyledSection1Registros = styled("div", {
    backgroundColor: "$lightgrey",
    padding: "100px 0 400px 0",
    "@media (max-width: 900px)": {
        padding: "100px 0 380px 0",
    },
    "@media (max-width: 720px)": {
        padding: "40px 0 520px 0",
    },

})

export const StyledSection1RegistrosContainer = styled("div", {
    position: "relative",
    maxWidth: "1200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    "@media (max-width: 1200px)": {
        maxWidth: "900px",
    },
    "@media (max-width: 900px)": {
        maxWidth: "600px",
    },
    "@media (max-width: 720px)": {
        maxWidth: "400px",
    },
})

export const StyledSection1RegistrosText = styled("div", {
    backgroundColor: "$white",
    width: "400px",
    zIndex: 2,
    padding: "20px 80px",
    borderRadius: "24px",
    position: "absolute",
    top: "300px",
    "@media (max-width: 900px)": {
        top: "280px",
        width: "360px",
        padding: "20px 60px",
    },
    "@media (max-width: 720px)": {
        top: "200px",
        width: "220px",
        padding: "20px 40px",
    },
})

export const StyledSection1RegistrosImage1 = styled("img", {
    width: "700px",
    position: "absolute",
    right: "200px",
    top: "60px",
    "@media (max-width: 1200px)": {
        right: "20px",
    },
    "@media (max-width: 900px)": {
        width: "500px",
        left: "140px",
        top: "160px",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
})

export const StyledSection1RegistrosImage2 = styled("img", {
    width: "500px",
    position: "absolute",
    left: "220px",
    bottom: "180px",
    "@media (max-width: 1200px)": {
        left: "80px",
    },
    "@media (max-width: 900px)": {
        left: "0px",
        bottom: "160px",
        width: "400px",
    },
    "@media (max-width: 720px)": {
        display: "none",
    },

})

export const StyledSection1RegistrosImage3 = styled("img", {
    width: "600px",
    zIndex: 1,
    "@media (max-width: 900px)": {
        width: "500px",
    },
    "@media (max-width: 720px)": {
        width: "320px",
    },
})

