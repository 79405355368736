import { styled } from "../../theme/styled";

export const StyledCardTerapia = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    position: "relative",
    marginBottom: "140px",
    "@media (max-width: 720px)": {
        marginBottom: "0",
        height: "100%",
    },
});

export const StyledCardImage = styled("img", {
    width: "560px",
    opacity: "0.9",
    "@media (max-width: 720px)": {
        width: "320px",
        margin: "0 auto",
    },
});

export const StyledCardText = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "550px",
    backgroundColor: "$light",
    borderRadius: "12px",
    padding: "20px 30px",
    position: "absolute",
    top: "120px",
    left: "30%",
    opacity: "0.9",
    "@media (max-width: 900px)": {
        width: "450px",
        left: "50%",
        transform: "translateX(-50%)",
    },
    "@media (max-width: 720px)": {
        width: "280px",
        top: "-40px",
        position: "relative",
        textAlign: "center",
    },
});

export const StyledCardParagraph = styled("div", {
    width: "450px",
    textAlign: "center",
    "@media (max-width: 720px)": {
        width: "300px",
        height: "100%",
    },
});

export const StyledCardButton = styled("div", {
    margin: "40px 0 30px 0",
    "@media (max-width: 720px)": {
        margin: "20px 0 10px 0",
        height: "100%",
    },
});